import { DwollaCustomerType } from '../../base/common/enums/dwolla-customer-type';

export default defineNuxtRouteMiddleware(() => {
  const { data } = useAuth();
  const $auth = data.value;

  if ($auth?.type !== DwollaCustomerType.Business) {
    return navigateTo('/');
  }
});
